<template>
  <Modal ref="modal">
    <ModalItem :label="lang['by-country']" @click.native="type('by-country')" />
    <ModalItem :label="lang['by-age']" @click.native="type('by-age')" />
    <ModalItem :label="lang['by-con']" @click.native="type('by-con')" />
  </Modal>
</template>

<script>
import Modal from "../widgets/Modal.vue";
import ModalItem from "../widgets/ModalItem.vue";
export default {
  components: { Modal, ModalItem },
  methods: {
    open: function() {
      this.$refs.modal.open();
    },
    close: function() {
      this.$refs.modal.close();
    },
    type: function(type) {
      this.close();
      this.$emit("type", type);
    },
  },
  computed: {
    lang: function() {
      return this.$locale.statistics;
    },
  },
};
</script>
