<template>
  <div
    :class="`modal view modal-view${_class ? ' ' + _class : ''} ${modalClass} ${modalSettings.class} ${modalSettings.clean ? '__clean' : ''}`"
    v-if="viewModal"
    :id="`modal-${this.modalID}`"
    @click="modaClick"
    :style="`--screen-width:${$screen.width}px;--screen-height:${$screen.height}px;`"
    :lock="$loading"
  >
    <div class="modal-content">
      <div class="modal-overlay" v-if="!$loading && isCloseOutside" @click="close"></div>
      <div class="modal-card" :noclose="!canClose" ref="card" :style="cardStyle" v-swipe="swipe">
        <div class="modal-card-header" v-if="modalSettings.header !== false">
          <div class="modal-card-header-left">
            <slot name="header"></slot>
          </div>
          <div class="modal-card-header-right" v-if="canClose">
            <slot name="header-right"></slot>
            <button class="button" @click="close" :onEscape="modalSettings.closeOnEsc" :aria-label="$locale['close']">
              <iconic name="times" />
            </button>
          </div>
        </div>
        <div class="modal-card-content">
          <slot></slot>
          <Spacer v-if="isBottomMenu && $isMobile" num="3" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "./Modal";
export default {
  mixins: [Modal],
  props: ["_class", "settings", "modal"],
  data: function() {
    return {
      viewModal: false,
      modalID: this.$global.fastID(),
      modalClass: "",
      header: null,
      swipe: {},
    };
  },
  methods: {
    modaClick: function(e) {
      if (this.modalSettings.closeOnClick) {
        this.close();
      }
    },
    elements: function(el) {
      const elmns = {
        modal: document.querySelector(`#modal-${this.modalID}`),
        card: document.querySelector(`#modal-${this.modalID} .modal-card`),
        custom: document.querySelector(el),
      };
      return elmns[el] || elmns["custom"];
    },
    open: function() {
      this.viewModal = true;
      this.elements("body").classList.add("onmodal");
      this.$emit("open");
      this.setVariables();
      this.checkOldModalAnimation("open");

      if (Go.is(this.modalSettings.open, "Function")) {
        this.modalSettings.open({ close: this.close, modal: this });
      }
    },
    close: async function(cb) {
      if (Go.is(this.onClose, "Function")) {
        this.onClose();
      }

      if (Go.is(this.modal && this.modal.close, "Function")) {
        return this.modal.close(cb);
      }

      this.modalClass = "closing";

      if (this.modalSettings.closeDelay) {
        await this.sleep(this.modalSettings.closeDelay);
      }

      await this.checkOldModalAnimation("close");

      this.viewModal = false;
      this.elements("body").classList.remove("onmodal");
      this.$emit("close");

      if (Go.is(cb, "Function")) {
        cb();
      }
    },
    setVariables: async function() {
      await this.sleep(100);
      const modal = document.getElementById(`modal-${this.modalID}`);
      this.modalElement = modal;

      if (!modal) {
        return this.setVariables();
      }

      this.header = modal.querySelector(".modal-card-header");

      if (this.header) {
        modal.style.setProperty("--header-height", this.header.offsetHeight + "px");
      }
    },
    screenResised: function(oldVal, newVal) {
      if (!newVal) {
        return this.setVariables();
      }

      this.cardWidth = this.$refs.card && this.$refs.card.offsetWidth;
      this.cardHeight = this.$refs.card && this.$refs.card.offsetHeight;

      if (this.modalElement) {
        this.modalElement.style["--card-width"] = this.cardWidth + "px";
        this.modalElement.style["--card-height"] = this.cardHeight + "px";
      }
    },
    checkOldModalAnimation: async function(type) {
      if (this.modal) return;

      return new Promise(async (resolve) => {
        const view = await Go.awaitUntilElement(`#modal-${this.modalID} .modal-card`);

        if (!this.animation || !view) {
          return resolve();
        }

        if (type === "open") {
          Go.animate({ el: view, ...this.settings }).open(() => {
            resolve();
          });
          return;
        }

        Go.animate({ el: view, ...this.settings }).close(() => {
          resolve();
        });
      });
    },
    init: function() {
      const self = this;
      if (this.modalSettings.closeOnSwipe) {
        this.swipe = {
          [self.modalSettings.closeOnSwipe]: () => {
            self.close();
          },
        };
      }
    },
  },
  computed: {
    onClose: function() {
      if (this.settings.onClose) {
        return this.settings.onClose;
      }

      if (this.modal && this.modal.onClose) {
        return this.modal.onClose;
      }

      if (this.modalSettings.onClose) {
        return this.modalSettings.onClose;
      }
    },
    cardStyle: function() {
      return (this.settings && this.settings.cardStyle) || {};
    },
    modalSettings: function() {
      return { ...this.settings };
    },
    isCloseOutside: function() {
      return this.modalSettings.outClose || this.modalSettings.closeOutside;
    },
    isBottomMenu: function() {
      let isbottom = this.modalSettings.class && this.modalSettings.class.includes("bottom");
      isbottom &= this.modalSettings.class && this.modalSettings.class.includes("menu");
      return isbottom;
    },
    canClose: function() {
      return this.modalSettings.noclose !== true && this.modalSettings.close !== false;
    },
  },
  destroyed: function() {
    const countModals = document.querySelectorAll(".modal-view");
    if (countModals.length < 1) {
      this.elements("body").classList.remove("onmodal");
    }
  },
  mounted: function() {
    this.init();
    this.$emit("mounted");
    if (this.modalSettings.opened) {
      this.open();
      this.screenResised();
    }
  },
  watch: {
    "$store.state.screen.width": function(oldVal, newVal) {
      this.screenResised(oldVal, newVal);
    },
    "$store.state.screen.height": function(oldVal, newVal) {
      this.screenResised(oldVal, newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  @include Fixed();
  z-index: 999;
  overflow: hidden;
  &-logo {
    color: $primary-color;
    font-size: 200%;
    margin: $mpadding/2 0 $mpadding $mpadding;
  }
  &-content {
    @include Fixed();
    display: grid;
    padding: $mpadding;
    background-color: var(--bg-overlay);
    overflow-y: auto;
  }
  &-overlay {
    @include Fixed();
    z-index: -1;
  }
  &-card {
    background-color: #fff;
    border-radius: $mradius;
    width: 100%;
    max-width: 280px;
    margin: auto;
    &-header {
      @include Flex(inherit, space-between, center);
      .button {
        @include Flex(inherit, center, center);
        padding: $mpadding;
        color: $sec_color;
        cursor: pointer;
        &:active {
          @include d-active();
        }
        &:hover {
          color: $primary_color;
        }
      }
      &-right {
        display: flex;
        align-items: flex-start;
      }
    }
    &-content {
      padding: 0 $mpadding $mpadding $mpadding;
    }
  }
  &.mk_head .modal-card-header {
    border-bottom: solid 1px $alto;
  }
  &.mobile-s {
    .modal-card {
      max-width: $mobile_s_width;
    }
  }
  &.mobile-m {
    .modal-card {
      max-width: $mobile_m_width;
    }
  }
  &[lock],
  &[lockapp],
  &[loading="true"] {
    * {
      pointer-events: none;
    }
  }
}
</style>
