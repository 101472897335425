export default {
  beforeMount: function() {
    this.checkAnimateStyle();
    this.$nextTick(() => {
      if (Go.is(this.modal && this.modal.open, "Function")) {
        this.modal.open(this.$el);
      }
    });
  },
  methods: {
    checkAnimateStyle: function() {
      this.hasAnimation = this.modal && (this.modal.animation || this.modal.animate);

      if (!this.hasAnimation) {
        this.hasAnimation = this.settings && (this.settings.animation || this.settings.animate);
      }

      if (!this.hasAnimation) {
        return;
      }

      if (this.modal) {
        this.animation = this.modal.animate || Go.animate(this.modal).getAnimation(this.modal.animation);
      }

      if (!this.animation && this.settings) {
        this.animation = this.settings.animate || Go.animate(this.settings).getAnimation(this.settings.animation);
      }

      if (!this.animation) {
        return;
      }

      this.style = { ...this.style, ...this.animation.from };
    },
  },
};
